/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Cell from "vux/src/components/cell/index.vue";
import Group from "vux/src/components/group/index.vue";
import Qrcode from "vux/src/components/qrcode/index.vue";
export default {
  name: 'VisitPermit',
  components: {
    Cell: Cell,
    Group: Group,
    Qrcode: Qrcode
  },
  data: function data() {
    return {
      visitType: '',
      currDate: '',
      visitor: {},
      //来访信息
      register: {},
      //注册信息
      busFlag: ['1'],
      inviteCompany: this.$utils.Store.getItem('orgName'),
      //邀请公司
      dicList: [{
        name: '拒绝',
        value: '0'
      }, {
        name: '允许',
        value: '1'
      }],
      isOperate: true,
      value: '',
      xh: '',
      saveImgUrl: ''
    };
  },
  computed: {},
  methods: {
    getVisitType: function getVisitType() {
      var _this = this;

      this.$api.Visit.getVisitType().then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== 600) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.visitType = data;
        }
      }).catch(function (err) {
        _this.$utils.Tools.httpError(err);
      });
    },

    /**
     *  function获取访客基本信息
     *  @wzx
     *  @2019-04-01
     **/
    getVisitorInfo: function getVisitorInfo(xh) {
      var _this2 = this;

      this.$utils.Tools.loadShow();
      this.$api.Visit.selectByPrimaryKey(xh).then(function (response) {
        _this2.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this2.$code.success) {
          _this2.$utils.Tools.toastShow(msg);
        } else {
          _this2.visitor = data.visUserEntity;

          if (!_this2.visitor.compName) {
            _this2.visitor.compName = '个人';
          }

          _this2.register = data.visRegisterEntity;

          _this2.drawImage();
        }
      }).catch(function (err) {
        _this2.$utils.Tools.httpError(err);
      });
    },
    getSysDate: function getSysDate() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this3.$utils.Tools.loadShow();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          _this3.currDate = data.sysDate;
        }
      }).catch(function (err) {
        _this3.$utils.Tools.httpError(err);
      });
    },
    drawImage: function drawImage() {
      var _this4 = this;

      var inviteBox = document.getElementById('visit-all');
      var canvas = document.createElement('canvas');
      canvas.width = 840; // 最终图片宽度280px的2倍，以px为单位

      canvas.height = 1050; // 最终图片高度350px的2倍，以px为单位

      var opts = {
        canvas: canvas,
        // 将自定义canvas作为配置项
        useCORS: true // 允许图片跨域

      };
      window.html2canvas(inviteBox, opts).then(function (canvas) {
        /* 此处的base64ImgSrc就是得到的img的base64字符串，直接在页面上显示即可 */
        var base64ImgSrc = canvas.toDataURL('image/png');
        _this4.saveImgUrl = base64ImgSrc;
      });
    }
  },
  created: function created() {
    this.xh = this.$route.query.xh || decodeURIComponent(this.$utils.Tools.getUrlParams('state')).split(',')[0];
    this.getVisitorInfo(this.xh);
    this.getVisitType();
  },
  mounted: function mounted() {},
  destroyed: function destroyed() {}
};